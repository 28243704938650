import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Grid,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AllInbox,
  Delete,
  Edit,
  ExpandMore,
  Link,
  QrCode,
  Visibility,
} from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { dropTipoRateio } from 'utils/drops';
import {
  checkInfosProduto,
  summarizer,
  findOnArray,
  encryptURL,
} from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog, useModal } from 'components/Modals';
import HistoricoVendaModal from 'components/Modals/HistoricoVendaModal';
import CatalogoModal from 'components/Modals/CatalogoModal';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import ButtonMenu from 'components/ButtonMenu';
import VirtualDrop from 'components/VirtualDrop';
import TableContainer from 'components/TableContainer';
import Blank from 'assets/blank.png';
import styles from '../styles';

const Itens = ({
  drops,
  onSubmit,
  onDelete,
  documento = {},
  disableActions,
  onSubmitImposto,
  onSubmitCfo,
  selected = [],
  setSelected,
}) => {
  const { openModal, closeModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const navigate = useNavigate();
  const rotina = 'DocumentoItem';

  const equals = (a = [], b = []) =>
    a.length === b.length && a.every((v, i) => v === b[i]);

  const onSelectAll = () => {
    if (equals(selected, documento?.DocumentoItems)) {
      setSelected([]);
    } else {
      setSelected(documento?.DocumentoItems);
    }
  };

  const getItensRemessas = () =>
    selected
      ?.map((s) => {
        const quantidade_gerada = summarizer(
          s?.Vinculos?.filter(
            (d) =>
              (d?.especie_natureza === 'FATURA' ||
                d?.especie_natureza === 'ESTOQUE') &&
              d?.terceiro === 'SIM'
          ),
          'quantidade'
        );
        const quantidade = s?.quantidade - quantidade_gerada;
        return {
          id: s?.id,
          quantidade,
          quantidade_max: quantidade,
          quantidade_total: s?.quantidade,
          quantidade_kit: s?.Kit?.quantidade,
          preco: s?.preco,
          preco_max: s?.preco,
          desconto_vlr: s?.desconto_vlr,
          desconto1_pc: s?.desconto1_pc,
          kit_id: s?.kit_id,
          produto_id: s?.produto_id,
          precobase_id: s?.precobase_id,
          documento_id: s?.documento_id,
          descricao: s?.descricao,
          subtotal:
            (quantidade || 0) * (s?.preco || 0) + (s?.desconto_vlr || 0),
        };
      })
      ?.filter((f) => Boolean(f?.quantidade) && f?.quantidade > 0);

  const Modal = ({ item }) => {
    let timer;
    const waitTime = 1000;
    const [useBarCode, setUserBarCode] = useState(false);
    const [additionals, setAdditionals] = useState(null);
    const [additionalLoading, setAdditionalsLoading] = useState(false);
    const { postLoading, documento } = useContext(DocumentosContext);
    const defaultValues = {
      id: item?.id,
      documento_id: documento?.id,
      produto_id: item?.produto_id || null,
      cdbarra: item?.cdbarra || '',
      descricao: item?.descricao || '',
      quantidade: item?.quantidade || '',
      unidade_medida_id: item?.unidade_medida_id || null,
      referencia: item?.referencia || '',
      preco: item?.preco || '',
      desconto1_pc: item?.desconto1_pc || '',
      desconto_vlr: item?.desconto_vlr || '',
      przent: item?.przent || null,
      planoconta_id: item?.planoconta_id || null,
      ccusto_id: item?.ccusto_id || null,
      aplicacao_id: item?.aplicacao_id || null,
      frota_id: item?.frota_id || null,
      infadc: item?.infadc || '',
    };
    const { control, handleSubmit, setValue, watch } = useForm({
      defaultValues,
    });

    const getTotal = () =>
      (watch('quantidade') || 1) * watch('preco') + watch('desconto_vlr');

    const getAdditionals = async ({ produto_id }) => {
      setAdditionals(null);
      if (produto_id && !(Boolean(item?.id) && !Boolean(item?.produto_id))) {
        try {
          setAdditionalsLoading(true);
          const produto = await checkInfosProduto({
            produto_id,
            filial_id: documento?.filial_id,
            cadastro_id: documento?.cadastro_id,
            disponivel: 'SIM',
          });
          setValue('descricao', produto?.descricao);
          if (!Boolean(watch('quantidade'))) {
            setValue('quantidade', produto?.volume || 1);
          }
          setValue('unidade_medida_id', produto?.unidade_medida_id);
          setValue('referencia', produto?.referencia);
          setValue(
            'preco',
            [37, 78]?.includes(documento?.natureza_operacao_id)
              ? produto?.custo
              : produto?.preco
          );
          setAdditionals({
            saldo: produto?.saldo,
            referencia: produto?.referencia,
            localizador: produto?.localizador,
            imagem: produto?.imagem,
          });
          if (useBarCode) {
            handleSubmit(onConfirm)();
          }
        } catch (error) {
        } finally {
          setAdditionalsLoading(false);
        }
      }
    };

    const callback = () => {
      setValue('produto_id', null);
      setValue('cdbarra', '');
      setValue('descricao', '');
      setValue('quantidade', '');
      setValue('unidade_medida_id', null);
      setValue('referencia', '');
      setValue('preco', '');
      setValue('desconto1_pc', '');
      setValue('desconto_vlr', '');
      setValue('przent', null);
      setValue('planoconta_id', null);
      setValue('ccusto_id', null);
      setValue('aplicacao_id', null);
      setValue('frota_id', null);
      setValue('infadc', '');
    };

    const onConfirm = (values) => {
      if (useBarCode) {
        const ext_prd = documento?.DocumentoItems?.find(
          (s) => s?.produto_id === values?.produto_id
        );
        if (Boolean(ext_prd) && !Boolean(item?.id)) {
          const quantidade =
            Number(ext_prd?.quantidade) + Number(values?.quantidade);
          onSubmit({ Itens: [{ ...ext_prd, quantidade }] }, callback);
        } else {
          onSubmit({ Itens: [values] }, !Boolean(item?.id) && callback);
        }
      } else {
        onSubmit({ Itens: [values] });
      }
    };

    useEffect(() => {
      const desconto_vlr =
        watch('desconto1_pc') * watch('preco') * watch('quantidade');
      setValue(
        'desconto_vlr',
        desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr
      );
    }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

    useEffect(() => {
      if (Boolean(watch('produto_id'))) {
        getAdditionals({ produto_id: watch('produto_id') });
      }
    }, [watch('produto_id')]);

    const itemOptions = [
      {
        name: 'Histórico de Vendas do Cliente',
        icon: 'work_history',
        action: () => {
          if (Boolean(documento?.cadastro_id)) {
            openDialog(
              <HistoricoVendaModal
                cadastro_id={documento?.cadastro_id}
                onSubmit={(data) => {
                  if (Boolean(data?.length)) {
                    onSubmit({
                      Itens: data?.map((d) => ({
                        ...d,
                        documento_id: documento?.id,
                      })),
                    });
                  }
                }}
              />,
              'Histórico de Vendas do Cliente'
            );
          } else {
            toastWarning('Documento não possui entidade');
          }
        },
      },
      {
        name: 'Histórico de Vendas do Produto',
        icon: 'work_history',
        action: () => {
          if (Boolean(watch('produto_id'))) {
            openDialog(
              <HistoricoVendaModal
                produto_id={watch('produto_id')}
                onSubmit={(data) => {
                  if (Boolean(data?.length)) {
                    onSubmit({
                      Itens: data?.map((d) => ({
                        ...d,
                        documento_id: documento?.id,
                      })),
                    });
                  }
                }}
              />,
              'Histórico de Vendas do Produto'
            );
          } else {
            toastWarning('Selecione um Produto');
          }
        },
      },
      {
        name: 'Catálogo de Produtos',
        icon: 'balance',
        action: () =>
          openDialog(
            <CatalogoModal
              onSubmit={(data) => {
                if (Boolean(data?.length)) {
                  onSubmit({
                    Itens: data?.map((d) => ({
                      produto_id: d?.id,
                      descricao: d?.descricao,
                      quantidade: d?.quantidade || 1,
                      referencia: d?.referencia,
                      unidade_medida_id: d?.unidade_medida_id,
                      preco: d?.preco,
                      documento_id: documento?.id,
                    })),
                  });
                }
              }}
              vendedor_id={documento?.DocumentoSaida?.vendedor_id}
              cadastro_id={documento?.cadastro_id}
            />,
            'Catálogo de Produtos'
          ),
      },
    ];

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            {!Boolean(item) &&
              (Boolean(documento?.Especie?.natureza === 'VENDA') ||
                Boolean(documento?.Especie?.natureza === 'ORCAMENTO')) && (
                <Grid item xs={12} textAlign="center">
                  <ButtonMenu
                    buttonProps={{ variant: 'outlined' }}
                    options={itemOptions}
                  />
                </Grid>
              )}
            <Grid item xs={12} display="flex" alignItems="center">
              <Box flex={1}>
                {Boolean(useBarCode) ? (
                  <Input
                    name="cdbarra"
                    control={control}
                    label="Código de Barras"
                    onKeyUp={(e) => {
                      clearTimeout(timer);
                      timer = setTimeout(() => {
                        const value = e?.target?.value;
                        if (Boolean(value)) {
                          const produto = drops?.Produto.find(
                            (f) => f?.cdbarra == e?.target?.value
                          );
                          setValue('produto_id', produto?.value || null);
                        }
                      }, waitTime);
                    }}
                  />
                ) : (
                  <VirtualDrop
                    name="produto_id"
                    control={control}
                    label="Produto"
                    options={drops?.Produto}
                  />
                )}
              </Box>
              <IconButton
                size="small"
                onClick={() => setUserBarCode(!useBarCode)}
                sx={{ ml: 1 }}
              >
                {Boolean(useBarCode) ? (
                  <AllInbox color="primary" />
                ) : (
                  <QrCode color="primary" />
                )}
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Input name="descricao" control={control} label="Descrição" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                name="quantidade"
                control={control}
                label="Quantidade"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                name="unidade_medida_id"
                control={control}
                label="Unidade de Medida"
                options={drops?.UnidadeMedida}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input name="referencia" control={control} label="Referência" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="desconto1_pc"
                control={control}
                label="% Desconto"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="preco"
                control={control}
                label="Preço Unitário"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="przent"
                control={control}
                label="Prazo de Entrega"
                type="date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="planoconta_id"
                control={control}
                label="Plano de Conta"
                options={drops?.Planoconta?.filter(
                  (p) => p?.operacional === 'SIM'
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="ccusto_id"
                control={control}
                label="Centro de Custo"
                options={drops?.Ccusto}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="aplicacao_id"
                control={control}
                label="Aplicação"
                options={drops?.Aplicacao}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="frota_id"
                control={control}
                label="Frota"
                options={drops?.Frota}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="infadc"
                control={control}
                label="Informações Adicionais"
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="div" align="center">
                Total:{' '}
                {getTotal()?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {Boolean(watch('produto_id')) && Boolean(additionals) && (
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  component="img"
                  sx={styles.image}
                  src={additionals?.imagem || Blank}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" component="div" align="center">
                  Saldo: {additionals?.saldo || '0'}{' '}
                  {additionals?.UnidadeMedida?.descricao || ''}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" component="div" align="center">
                  Referência: {additionals?.referencia || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" component="div" align="center">
                  Localização: {additionals?.localizador || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading || additionalLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const MultiModal = ({ itens = [] }) => {
    const { postLoading } = useContext(DocumentosContext);
    const defaultValues = {
      przent: null,
      pedcomp: null,
      planoconta_id: null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });

    const onConfirm = ({ przent, pedcomp, planoconta_id }) => {
      const Itens = itens?.map((i) => ({
        id: i?.id,
        documento_id: i?.documento_id,
        ...(Boolean(przent) && { przent }),
        ...(Boolean(pedcomp) && { pedcomp }),
        ...(Boolean(planoconta_id) && { planoconta_id }),
      }));

      onSubmit({ Itens });
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">Produtos Selecionados</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {itens?.map((i) => (
                <Box my={1}>
                  <Typography variant="caption">
                    • {i?.referencia} - {i?.descricao}
                  </Typography>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="przent"
            control={control}
            label="Prazo de Entrega"
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="planoconta_id"
            control={control}
            label="Plano de Conta"
            options={drops?.Planoconta?.filter((p) => p?.operacional === 'SIM')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="pedcomp" control={control} label="Pedido de Compra" />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const AverageModal = ({ type }) => {
    const { postLoading } = useContext(DocumentosContext);
    const { control, handleSubmit, watch, setValue } = useForm({
      defaultValues: { type: type || null, percentual: '', valor: '' },
    });

    useEffect(() => {
      if (Boolean(watch('percentual'))) {
        const valor = (documento?.Totais?.preco * watch('percentual')) / 100;
        setValue('valor', valor);
      }
    }, [watch('percentual')]);

    const onConfirm = ({ type, valor }) => {
      const isMargem =
        type === 'internacionalizacao' ||
        type === 'fretend' ||
        type === 'segurond' ||
        type === 'impostond' ||
        type === 'comissaond';
      if (!Boolean(type)) {
        return toastWarning('Selecione o tipo');
      }

      let valor_lancado = valor;
      const Itens = documento?.DocumentoItems?.map((i, idx) => {
        const total = documento?.Totais?.preco;
        const preco = i?.preco;
        const quantidade = i?.quantidade;
        const average = parseFloat((preco * quantidade) / total);
        const average_total = valor * average;
        const value =
          idx + 1 === documento?.DocumentoItems?.length
            ? valor_lancado
            : parseFloat(average_total?.toFixed(2));
        valor_lancado -= value;
        if (isMargem) {
          return {
            id: i?.id,
            documento_id: i?.documento_id,
            Margem: {
              documento_item_id: i?.DocumentoItemMc?.documento_item_id,
              [type]: type === 'desconto_vlr' ? -value : value,
            },
          };
        } else {
          return {
            id: i?.id,
            documento_id: i?.documento_id,
            [type]: type === 'desconto_vlr' ? -value : value,
          };
        }
      });
      onSubmit({ Itens });
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="type"
            control={control}
            label="Tipo de Divisão"
            options={dropTipoRateio}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="percentual" control={control} label="Percentual" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="valor" control={control} label="Valor" />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            color="primary"
            variant="outlined"
            loading={postLoading}
            onClick={handleSubmit(onConfirm)}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const ImpostosModal = () => {
    const [itens, setItens] = useState([]);
    const defaultValues = { imposto: 1 };
    const { control, watch } = useForm({ defaultValues });

    useEffect(() => {
      setItens(
        documento?.DocumentoItems?.map((di) => {
          let ret = {
            id: di?.id,
            descricao: di?.descricao,
            referencia: di?.referencia,
            cfo: (di?.Cfo?.cfo || '') + ' - ' + (di?.Cfo?.descricao || ''),
            ncm: di?.Cfiscal?.ncm || '',
            orgtrib: di?.Produto?.OrigemTributarium?.origem,
          };
          di?.DocumentoItemImpostos?.map((dii) => {
            ret[dii?.tributo_id?.toString()] = {
              ['Alíquota']: `${dii?.percentual || 0}%`,
              ['Base de Cálculo']: (dii?.basecalculo || 0)?.toLocaleString(
                'pt-br',
                {
                  style: 'currency',
                  currency: 'BRL',
                }
              ),
              Valor: (dii?.valor || 0)?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }),
              valor: dii?.valor,
              ['Situação Tributária']: `${
                dii?.SituacaoTributarium?.cst || ''
              } - ${dii?.SituacaoTributarium?.descricao || ''}`,
            };
          });
          return ret;
        })
      );
    }, []);

    const style = {
      container: { width: '100%' },
      line: { display: 'flex', my: 1 },
      label: { fontWeight: 'bold', flex: 2 },
      value: { flex: 3 },
      labelCard: { fontWeight: 'bold' },
      valueCard: { ml: 1 },
      input: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
      },
    };

    const entidade = findOnArray(documento?.cadastro_id, drops?.Entidade);

    const contribuinte = findOnArray(
      documento?.contribuinte_id,
      drops?.Contribuinte
    );

    const Cell = ({ row = {} }) => (
      <Box sx={style?.container}>
        {Object?.keys(row)
          ?.filter(
            (f) =>
              f?.substring(0, 1) === f?.substring(0, 1)?.toUpperCase() ||
              f === 'label'
          )
          ?.map((m) => (
            <Box key={m} sx={style?.line}>
              <Typography sx={style?.label} variant="body2">
                {m}:{' '}
              </Typography>
              <Typography sx={style?.value} variant="body2">
                {row[m]}
              </Typography>
            </Box>
          ))}
      </Box>
    );

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Card>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box sx={style?.line}>
                  <Typography sx={style?.labelCard}>Nome: </Typography>
                  <Typography sx={style?.valueCard}>
                    {entidade?.label}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={style?.line}>
                  <Typography sx={style?.labelCard}>Contribuinte: </Typography>
                  <Typography sx={style?.valueCard}>
                    {contribuinte?.Código} - {contribuinte?.label}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={style?.line}>
                  <Typography sx={style?.labelCard}>Fantasia: </Typography>
                  <Typography sx={style?.valueCard}>
                    {entidade?.Fantasia}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={style?.line}>
                  <Typography sx={style?.labelCard}>Uso Final: </Typography>
                  <Typography sx={style?.valueCard}>
                    {documento?.usofinal}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={style?.line}>
                  <Typography sx={style?.labelCard}>Cidade: </Typography>
                  <Typography sx={style?.valueCard}>
                    {entidade?.Cidade}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} sx={style?.input}>
          <Dropdown
            name="imposto"
            control={control}
            label="Tributo"
            options={drops?.Tributo}
          />
          <Typography variant="h6" align="center" color="primary">
            Total do Tributo:{' '}
            {summarizer(
              itens?.map((m) => m[watch('imposto')])?.filter(Boolean),
              'valor'
            )?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={itens}
            columns={[
              {
                field: 'actions',
                type: 'actions',
                width: 100,
                headerName: 'Ações',
                getActions: ({ id }) => {
                  const item = documento?.DocumentoItems?.find(
                    (f) => f?.id === id
                  );
                  return [
                    <GridActionsCellItem
                      icon={<Visibility />}
                      label="Detalhes"
                      onClick={() => {
                        navigate(`/app/Documento/Item`, {
                          state: { item, disableActions },
                        });
                        closeDialog();
                      }}
                    />,
                  ];
                },
              },
              {
                field: 'id',
                headerName: 'ID',
                width: 100,
                sortable: false,
              },
              {
                field: 'referencia',
                headerName: 'Referência',
                width: 100,
                sortable: false,
              },
              {
                field: 'descricao',
                headerName: 'Descrição',
                width: 300,
                sortable: false,
              },
              ...drops?.Tributo?.filter(
                (f) => f?.value == watch('imposto')
              )?.map((i) => ({
                field: i?.value,
                headerName: i?.label,
                width: 500,
                sortable: false,
                renderCell: ({ row }) => <Cell row={row[i?.value]} />,
              })),
              {
                field: 'orgtrib',
                headerName: 'Origem Tributária',
                width: 150,
                sortable: false,
              },
              {
                field: 'cfo',
                headerName: 'CFOP',
                width: 450,
                sortable: false,
              },
              {
                field: 'ncm',
                headerName: 'NCM',
                width: 150,
                sortable: false,
              },
            ]}
            hideFooter
            autoHeight
            density="compact"
            disableSelectionOnClick
            disableColumnMenu
            showCellRightBorder
            showColumnRightBorder
            getRowHeight={() => 'auto'}
            localeText={{ noRowsLabel: 'Nenhum Registro.' }}
          />
        </Grid>
      </Grid>
    );
  };

  const options = [
    {
      name: 'Adicionar Item',
      icon: 'add',
      action: () => openDialog(<Modal />, 'Adicionar Item'),
    },
    {
      name: 'Editar Selecionados',
      icon: 'edit',
      action: () => {
        if (Boolean(selected?.length)) {
          openDialog(<MultiModal itens={selected} />, 'Editar Itens');
        } else {
          toastWarning('Selecione ao menos um item');
        }
      },
    },
    {
      name: 'Consignação',
      icon: 'change_circle',
      show:
        (documento?.especie_id === 10 || documento?.especie_id === 25) &&
        documento?.natureza_operacao_id === 37,
      action: () => {
        if (!Boolean(selected?.length)) {
          return toastWarning('Selecione ao menos um item');
        } else {
          navigate(`/app/Remessas/Consignar`, {
            state: {
              itens: getItensRemessas(),
              vendedor_id: documento?.cadastro_id,
            },
          });
        }
      },
    },
    {
      name: 'Recarregar Tributos',
      icon: 'refresh',
      action: () => {
        if (Boolean(selected?.length)) {
          onSubmitImposto(selected.map((s) => s?.id));
        } else {
          toastWarning('Selecione ao menos um item');
        }
      },
    },
    {
      name: 'Recarregar CFOP',
      icon: 'refresh',
      action: () => {
        if (Boolean(selected?.length)) {
          onSubmitCfo(selected.map((s) => s?.id));
        } else {
          toastWarning('Selecione ao menos um item');
        }
      },
    },
    {
      name: 'Outros Valores',
      icon: 'splitscreen',
      show: Boolean(documento?.DocumentoItems?.length),
      action: () => {
        if (Boolean(documento?.DocumentoItems?.length)) {
          openDialog(<AverageModal />, 'Outros Valores');
        } else {
          toastWarning('Documento não possui item');
        }
      },
    },
    {
      name: 'Remover Estrutura Retornável',
      icon: 'delete',
      action: () => {
        if (Boolean(selected?.length)) {
          const Itens = [];
          selected
            ?.filter((f) => Boolean(f?.DocumentoItemEstruturas?.length))
            ?.map((d) =>
              Itens?.push({
                id: d?.id,
                documento_id: d?.documento_id,
                Estruturas: d?.DocumentoItemEstruturas?.map((e) => ({
                  ...e,
                  retornavel: 'NAO',
                })),
              })
            );
          onSubmit({ Itens });
        } else {
          toastWarning('Selecione ao menos um item');
        }
      },
    },
  ];

  const Line = ({ value, label, type }) => (
    <Grid item xs={12} sm={6}>
      <Box display="flex" alignItems="center">
        {Boolean(type) ? (
          <Typography
            sx={disableActions ? styles?.lineLabel : styles?.lineLabelLink}
            variant="subtitle2"
            onClick={() =>
              !disableActions &&
              openDialog(<AverageModal type={type} />, 'Outros Valores')
            }
          >
            {label}:
          </Typography>
        ) : (
          <Typography sx={styles?.lineLabel} variant="subtitle2">
            {label}:
          </Typography>
        )}
        <Typography sx={styles?.lineValue} variant="body2">
          {value}
        </Typography>
      </Box>
    </Grid>
  );

  return (
    <>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          {!disableActions && (
            <Grid item xs={12} textAlign="center">
              <ButtonMenu
                buttonProps={{ variant: 'outlined' }}
                options={options}
              />
            </Grid>
          )}
          {!!documento?.DocumentoItems?.length && (
            <Grid item xs={12}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={equals(selected, documento?.DocumentoItems)}
                          onChange={onSelectAll}
                        />
                      </TableCell>
                      <TableCell>Sequencia</TableCell>
                      <TableCell>Código do Produto</TableCell>
                      <TableCell>Produto</TableCell>
                      <TableCell>Referência</TableCell>
                      {!disableActions &&
                        documento?.natureza_operacao_id !== 37 &&
                        documento?.natureza_operacao_id !== 52 && (
                          <TableCell>Saldo</TableCell>
                        )}
                      <TableCell>Quantidade</TableCell>
                      <TableCell>Preço</TableCell>
                      <TableCell>Desconto</TableCell>
                      <TableCell>
                        <Typography
                          sx={styles?.headerLink}
                          variant="body2"
                          onClick={() =>
                            openDialog(<ImpostosModal />, 'Impostos')
                          }
                        >
                          Impostos
                        </Typography>
                      </TableCell>
                      <TableCell>Valor Total</TableCell>
                      {(documento?.especie_id === 10 ||
                        documento?.especie_id === 25 ||
                        documento?.especie_id === 43) &&
                        (documento?.natureza_operacao_id === 37 ||
                          documento?.natureza_operacao_id === 52) && (
                          <TableCell>Kit</TableCell>
                        )}
                      {documento?.especie_id === 9 && (
                        <TableCell>Quantidade Requisitada</TableCell>
                      )}
                      {documento?.especie_id === 9 && (
                        <TableCell>Documento de Compra</TableCell>
                      )}
                      <TableCell>Origem</TableCell>
                      {Boolean(
                        documento?.NaturezaOperacao?.vinculo === 'SIM'
                      ) && <TableCell align="center">Vínculo</TableCell>}
                      <TableCell align="center">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documento?.DocumentoItems?.map((item) => {
                      const [anchorEl, setAnchorEl] = useState(null);
                      const origem = {
                        value: Boolean(item?.DocumentoItemOrigems?.length)
                          ? item?.DocumentoItemOrigems[0]?.documento_origem ||
                            'Nº não informado'
                          : null,
                        hint: item?.DocumentoItemOrigems[0]?.especie_origem,
                      };
                      const vinculo = {
                        value: Boolean(item?.Vinculados?.length)
                          ? item?.Vinculados[0]?.documento_vinculado ||
                            'Nº não informado'
                          : null,
                        hint: item?.Vinculados[0]?.especie_vinculado,
                      };
                      const isVinculo =
                        documento?.NaturezaOperacao?.vinculo === 'SIM';
                      const impostos = item?.DocumentoItemImpostos?.filter(
                        (f) => f?.SituacaoTributarium?.operacaotd === 'SOMA'
                      );
                      let qtgerada = 0;
                      (Boolean(isVinculo)
                        ? item?.Vinculados || []
                        : item?.DocumentoItemOrigems || []
                      )?.map((m) => (qtgerada += m?.quantidade || 0));
                      const quantidade =
                        (item?.quantidade || 0) - (qtgerada || 0);
                      return (
                        <TableRow key={item?.id?.toString()}>
                          <TableCell>
                            <Checkbox
                              color="primary"
                              checked={selected?.includes(item)}
                              onChange={() => {
                                if (selected?.includes(item)) {
                                  setSelected(
                                    selected?.filter((s) => item !== s)
                                  );
                                } else {
                                  if (Boolean(item?.kit_id)) {
                                    setSelected([
                                      ...selected?.filter(
                                        (f) => f?.kit_id !== item?.kit_id
                                      ),
                                      ...documento?.DocumentoItems?.filter(
                                        (f) => f?.kit_id === item?.kit_id
                                      ),
                                    ]);
                                  } else {
                                    setSelected([...selected, item]);
                                  }
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell>{item?.sequencia}</TableCell>
                          <TableCell>{item?.produto_id}</TableCell>
                          <TableCell>{item?.descricao}</TableCell>
                          <TableCell>{item?.referencia}</TableCell>
                          {!disableActions &&
                            documento?.natureza_operacao_id !== 37 &&
                            documento?.natureza_operacao_id !== 52 && (
                              <TableCell>{item?.saldo}</TableCell>
                            )}
                          <TableCell>
                            {item?.quantidade} {item?.UnidadeMedida?.sigla}
                          </TableCell>
                          <TableCell>
                            {(item?.preco * 1)?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell>
                            {item?.desconto_vlr?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={styles?.lineLabelLink}
                              variant="body2"
                              onClick={(e) => setAnchorEl(e?.currentTarget)}
                            >
                              {(
                                summarizer(impostos, 'valor') || 0
                              )?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Typography>
                            <Popover
                              open={Boolean(anchorEl)}
                              anchorEl={anchorEl}
                              onClose={() => setAnchorEl(null)}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                            >
                              {item?.DocumentoItemImpostos?.map((m) => {
                                const color =
                                  m?.SituacaoTributarium?.operacaotd === 'SOMA'
                                    ? 'success.main'
                                    : m?.SituacaoTributarium?.operacaotd ===
                                      'SUBTRAI'
                                    ? 'error.main'
                                    : 'grey';
                                return (
                                  <Box
                                    key={m?.id?.toString()}
                                    sx={styles?.popover}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      color={color}
                                    >
                                      {m?.Tributo?.descricao}:
                                    </Typography>
                                    <Typography
                                      key={m?.id?.toString()}
                                      variant="subtitle2"
                                      color={color}
                                    >
                                      {m?.valor?.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                      })}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Popover>
                          </TableCell>
                          <TableCell>
                            {(
                              item?.preco * (item?.quantidade || 1) +
                              item?.desconto_vlr
                            )?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          {(documento?.especie_id === 10 ||
                            documento?.especie_id === 25 ||
                            documento?.especie_id === 43) &&
                            (documento?.natureza_operacao_id === 37 ||
                              documento?.natureza_operacao_id === 52) && (
                              <TableCell>{item?.kit_id}</TableCell>
                            )}

                          {documento?.especie_id === 9 && (
                            <TableCell>
                              {summarizer(
                                item?.Destinos?.filter(
                                  (d) =>
                                    d?.especie_id === 2 &&
                                    d?.natureza_operacao_id === 1
                                ),
                                'quantidade'
                              )}
                            </TableCell>
                          )}
                          {documento?.especie_id === 9 && (
                            <TableCell>
                              {
                                item?.Destinos?.find(
                                  (d) =>
                                    d?.especie_id === 2 &&
                                    d?.natureza_operacao_id === 1 &&
                                    Boolean(d?.ped_fornec)
                                )?.ped_fornec
                              }
                            </TableCell>
                          )}
                          <TableCell>
                            {Boolean(origem?.hint) ? (
                              <Tooltip placement="left" title={origem?.hint}>
                                <Typography variant="body2">
                                  {origem?.value}
                                </Typography>
                              </Tooltip>
                            ) : (
                              origem?.value
                            )}
                          </TableCell>
                          {Boolean(
                            documento?.NaturezaOperacao?.vinculo === 'SIM'
                          ) && (
                            <TableCell align="center">
                              {Boolean(vinculo?.hint) ? (
                                <Tooltip placement="left" title={vinculo?.hint}>
                                  <Typography variant="body2">
                                    {vinculo?.value}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                vinculo?.value
                              )}
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {!disableActions && (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <Modal item={item} />,
                                    'Editar Item'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                            )}
                            {!disableActions && (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  onDelete({ rotina, id: item?.id })
                                }
                              >
                                <Delete />
                              </IconButton>
                            )}
                            <IconButton
                              size="small"
                              onClick={() =>
                                navigate(`/app/Documento/Item`, {
                                  state: { item, disableActions },
                                })
                              }
                            >
                              <Visibility />
                            </IconButton>
                            {Boolean(quantidade > 0) && (
                              <IconButton
                                size="small"
                                onClick={() => {
                                  const params = {
                                    id: item?.id,
                                    descricao: item?.descricao,
                                    um: item?.UnidadeMedida?.sigla,
                                    documento_id: documento?.id,
                                    cadastro_id: documento?.cadastro_id,
                                    filial_id: documento?.filial_id,
                                    vinculo: isVinculo,
                                    quantidade,
                                  };
                                  navigate(
                                    `/app/Documento/Vinculo/${encryptURL(
                                      params
                                    )}`
                                  );
                                }}
                              >
                                <Link />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Card>
      <Card title="Totais" style={styles?.card}>
        <Grid container spacing={2}>
          <Line value={documento?.Totais?.peso || 0} label="Peso" />
          <Line value={documento?.Totais?.volume || 0} label="Volume" />
          <Line
            value={(documento?.Totais?.preco || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Preço"
          />
          <Line
            value={(documento?.Totais?.frete || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Frete"
            type="frete"
          />
          <Line
            value={(documento?.Totais?.desconto || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Desconto"
            type="desconto_vlr"
          />
          <Line
            value={(documento?.Totais?.seguro || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Seguro"
            type="seguro"
          />
          <Line
            value={(documento?.Totais?.despac || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Despesas Acessórias"
            type="despac"
          />
          <Line
            value={(documento?.Totais?.outros || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Outros"
            type="outros"
          />
          <Line
            value={(documento?.Totais?.eventuais || 0)?.toLocaleString(
              'pt-br',
              {
                style: 'currency',
                currency: 'BRL',
              }
            )}
            label="Eventuais"
            type="eventuais"
          />
          <Line
            value={(documento?.Totais?.impostos || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Impostos"
          />
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Total:{' '}
              {(documento?.Totais?.documento || 0)?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default Itens;
