import { createContext, useState } from 'react';
import api from 'services/api';
import moment from 'moment';

const filter = {
  like: '',
  id: '',
  status: ['PENDENTE'],
  situacao: [],
  natureza_operacao: [],
  modelo: [],
  serie: '',
  marketplace_name: [],
  dtemissao: {
    i: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    f: null,
  },
  documento: '',
  entidade: [],
  vldocto: '',
  forma_pagto: '',
  whatsapp: '',
  vendedor: [],
};

export const NotasContext = createContext();

export const NotasProvider = ({ children }) => {
  const [notas, setNotas] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtemissao', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  const getNotas = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: notas?.filter,
        page: notas?.page,
        size: notas?.size,
        order: notas?.order,
        ...payload,
      });
      setNotas((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getConsultaNota = async ({ id, type, cb }) => {
    try {
      setGetLoading(true);
      await api.get(`/${type}/Consultar/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postInutilizacao = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/NFe/Inutilizacao`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const exportXML = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Comercial/Notas/Exportar`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const exportXMLAll = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Comercial/Notas/Exportar/All`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const exportXMLInutil = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Comercial/Notas/Exportar/Inutil`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const importXML = async ({ formData, cb }) => {
    try {
      setPostLoading(true);
      await api.post(`/Comercial/Notas/Importar`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <NotasContext.Provider
      value={{
        notas,
        getNotas,
        getLoading,
        getConsultaNota,
        postLoading,
        postInutilizacao,
        exportXML,
        exportXMLAll,
        exportXMLInutil,
        importXML,
      }}
    >
      {children}
    </NotasContext.Provider>
  );
};
