import React, { forwardRef, useContext, useState } from 'react';
import {
  Autocomplete,
  Box,
  IconButton,
  Tooltip,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import Input from 'components/Input';
import styles from './styles';

const Dropdown = forwardRef(
  (
    {
      control,
      name,
      options = [],
      label,
      onValueChange,
      refresh,
      busca_refinada,
      ...props
    },
    ref
  ) => {
    const { getDrops } = useContext(DropsContext);
    const [refinada, setRefinada] = useState(false);

    const onRefresh = () => getDrops([refresh]);

    const onRefinada = () => setRefinada((prev) => !prev);

    const getFilterOptions = (options, state) => {
      const getRelevantKeys = (option) =>
        Object.keys(option).filter(
          (key) =>
            key.charAt(0) === key.charAt(0).toUpperCase() || key === 'label'
        );

      const filterOptions = createFilterOptions({
        stringify: (option) => {
          const relevantKeys = getRelevantKeys(option);
          return relevantKeys.map((key) => option[key]).join('');
        },
      });

      return filterOptions(options, state).filter((option) => {
        if (refinada && state?.inputValue) {
          const relevantKeys = getRelevantKeys(option);
          return relevantKeys.some((key) => option[key] == state?.inputValue);
        }
        return true;
      });
    };

    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Box sx={styles?.container}>
            <Autocomplete
              {...props}
              clearOnEscape
              sx={{ ...styles?.drop, ...props?.sx }}
              options={options || []}
              value={field?.value}
              filterOptions={getFilterOptions}
              renderInput={(params) => (
                <Input
                  {...params}
                  {...field}
                  label={label}
                  onChange={() => {}}
                />
              )}
              renderOption={(props, option) => {
                return (
                  <Box
                    {...props}
                    key={option.value}
                    sx={styles?.optionContainer}
                  >
                    <Typography sx={styles?.optionLabel} variant="body1">
                      {option.label}
                    </Typography>
                    {Object?.keys(option)
                      ?.filter(
                        (f) =>
                          f?.substring(0, 1) ===
                          f?.substring(0, 1)?.toUpperCase()
                      )
                      ?.map((o, i) => (
                        <Typography
                          key={i?.toString()}
                          sx={styles?.optionSubLabel}
                          variant="body2"
                        >
                          {o}: {option[o]}
                        </Typography>
                      ))}
                  </Box>
                );
              }}
              onChange={(_, data) => {
                if (!!props?.multiple) {
                  field?.onChange(data?.map((d) => d.value || d));
                  if (!!onValueChange) {
                    onValueChange(data?.map((d) => d.value || d));
                  }
                } else {
                  field?.onChange(data?.value || null);
                  if (!!onValueChange) {
                    onValueChange(data || null);
                  }
                }
              }}
              getOptionLabel={(option) => {
                const ret = options?.find((c) => c.value === option);
                return option?.label || ret?.label || '';
              }}
              isOptionEqualToValue={(option, value) => option.value === value}
            />
            {Boolean(refresh) && (
              <Tooltip title="Atualizar" placement="top">
                <IconButton size="small" onClick={onRefresh}>
                  <Refresh fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {Boolean(busca_refinada) && (
              <Tooltip
                title={
                  (busca_refinada && refinada ? 'Desativar' : 'Ativar') +
                  ' Busca Refinada'
                }
                placement="top"
              >
                <IconButton size="small" onClick={onRefinada}>
                  {busca_refinada && refinada ? (
                    <SearchOff fontSize="small" />
                  ) : (
                    <SavedSearch fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      />
    );
  }
);

export default Dropdown;
