import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit, QueryStats, Visibility } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { dropFretePorConta, dropIndPres, dropBoolean } from 'utils/drops';
import {
  checkInfosProduto,
  findOnArray,
  summarizer,
  getDatePagto,
} from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog, useModal } from 'components/Modals';
import HistoricoVendaModal from 'components/Modals/HistoricoVendaModal';
import CatalogoModal from 'components/Modals/CatalogoModal';
import TableContainer from 'components/TableContainer';
import VirtualDrop from 'components/VirtualDrop';
import ButtonMenu from 'components/ButtonMenu';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import Blank from 'assets/blank.png';
import api from 'services/api';
import ItemModal from './Modals/ItemModal';
import PagamentoModal from './Modals/PagamentoModal';
import styles from './styles';
import moment from 'moment';

const Gerar = () => {
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postDocumento, postLoading } = useContext(DocumentosContext);
  const defaultValues = {
    natureza_operacao_id: 6,
    documento: '',
    ped_cliente: '',
    dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
    filial_id: user?.filial_id,
    cadastro_id: null,
    contribuinte_id: null,
    usofinal: null,
    indpres: null,
    vendedor_id: null,
    entidade_endereco_id: null,
    moeda_id: null,
    cotacao: '',
    observacao: '',
    dadosadc: '',
    haver: '',
    haver_uso: false,
    //ITENS
    produto_id: null,
    descricao: '',
    quantidade: '',
    unidade_medida_id: null,
    referencia: '',
    desconto1_pc: '',
    desconto_vlr: '',
    preco: '',
    przent: null,
    infadc: '',
    itens: [],
    //PAGAMENTO
    forma_pagto_id: null,
    condicao_pagamento_id: null,
    fixar: false,
    pagamento: [],
    //ENVIO/TRANSPORTE
    forma_envio_id: null,
    freteenv: '',
    fretecob: '',
    freterec: '',
    fretevlr: '',
    fretepc: null,
    entidade_id: null,
  };
  const navigate = useNavigate();
  const [additionals, setAdditionals] = useState(null);
  const [loading, setLoading] = useState(false);
  const { openModal, closeModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const { control, handleSubmit, resetField, watch, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    const desconto_vlr =
      watch('desconto1_pc') * watch('preco') * watch('quantidade');
    setValue(
      'desconto_vlr',
      desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr
    );
  }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

  const subtotal =
    (watch('quantidade') || 0) * (watch('preco') || 0) +
    (watch('desconto_vlr') || 0);

  const getAdditionals = async (produto_id) => {
    setLoading(true);
    const produto = await checkInfosProduto({
      produto_id,
      disponivel: 'SIM',
      filial_id: watch('filial_id'),
      vendedor_id: watch('vendedor_id'),
      cadastro_id: watch('cadastro_id'),
      condicao_pagamento_id: watch('condicao_pagamento_id'),
    });
    setAdditionals(produto);
    setValue('descricao', produto?.descricao);
    setValue('desconto1_pc', produto?.desconto1_pc);
    setValue('quantidade', produto?.quantidade || 1);
    setValue('unidade_medida_id', produto?.unidade_medida_id);
    setValue('referencia', produto?.referencia);
    setValue('preco', produto?.preco);
    setLoading(false);
  };

  const onAddItem = (values) => {
    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue('itens', [
      {
        subtotal,
        produto_id: values?.produto_id,
        descricao: values?.descricao,
        quantidade: values?.quantidade,
        unidade_medida_id: values?.unidade_medida_id,
        referencia: values?.referencia,
        desconto1_pc: values?.desconto1_pc,
        desconto_vlr: values?.desconto_vlr,
        preco: values?.preco,
        przent: values?.przent,
        infadc: values?.infadc,
      },
      ...values?.itens,
    ]);
    setAdditionals(null);
    resetField('produto_id');
    resetField('descricao');
    resetField('quantidade');
    resetField('unidade_medida_id');
    resetField('referencia');
    resetField('desconto1_pc');
    resetField('desconto_vlr');
    resetField('preco');
    resetField('przent');
    resetField('infadc');
    handleSubmit(onRecalc)();
  };

  const onAddItemDialog = (v) => {
    setValue('itens', [...v, ...watch('itens')]);
    handleSubmit(onRecalc)();
  };

  const onAddItemModal = (v) => {
    const data = v?.map((i) => ({
      produto_id: i?.id,
      descricao: i?.descricao,
      quantidade: i?.quantidade || 1,
      unidade_medida_id: i?.unidade_medida_id,
      referencia: i?.referencia,
      preco: i?.preco,
      subtotal: (i?.preco || 0) * (i?.quantidade || 1),
    }));
    setValue('itens', [...data, ...watch('itens')]);
    handleSubmit(onRecalc)();
  };

  const onEditItem = ({ values, index }) => {
    const itens = watch('itens');
    const item = itens[index];
    if (values?.quantidade > item?.quantidade_max) {
      return toastWarning(
        `Quantidade limite excedida. (Limite: ${item?.quantidade_max})`
      );
    }
    if (Boolean(item?.preco_max) && values?.preco > item?.preco_max) {
      return toastWarning(
        `Preço limite excedido. (Limite: ${item?.preco_max})`
      );
    }

    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue(
      'itens',
      itens?.map((itm, i) =>
        index === i ? { ...itm, ...values, subtotal } : itm
      )
    );
    handleSubmit(onRecalc)();
    closeDialog();
  };

  const onDeleteItem = (index) => {
    setValue(
      'itens',
      watch('itens')?.filter((_, i) => i !== index)
    );
    handleSubmit(onRecalc)();
  };

  const onChangePagto = (values) => {
    const forma_pagto_id = values?.forma_pagto_id;
    const condicao_pagamento_id = values?.condicao_pagamento_id;
    const fixar = values?.fixar;
    if (Boolean(forma_pagto_id) && Boolean(condicao_pagamento_id)) {
      const condicoes =
        findOnArray(forma_pagto_id, drops?.FormaDePagamento, 'condicoes') || [];
      const cond_pagto = findOnArray(condicao_pagamento_id, condicoes);
      let valor_total = summarizer(values?.itens, 'subtotal');
      const pagamento = [];
      if (values?.haver_uso && valor_total > 0) {
        const forma_pagto_id = drops?.FormaDePagamento?.find(
          (f) => f?.modulo === 'VENDAS' && f?.modalidade === '05-CRÉDITO LOJA'
        )?.value;

        const valor = values?.haver > valor_total ? valor_total : values?.haver;
        pagamento?.push({
          dtvenc: moment().format('YYYY-MM-DD'),
          forma_pagto_id,
          valor,
        });
        valor_total -= valor;
      }
      if (cond_pagto && valor_total > 0) {
        let valor_lancado = 0;
        for (let parcela = 1; parcela <= cond_pagto?.nvezes; parcela++) {
          const valor = parseFloat(
            (valor_total / cond_pagto?.nvezes).toFixed(2)
          );
          pagamento.push({
            dtvenc: getDatePagto({
              ...cond_pagto,
              parcela,
              fixar,
              start_date: values?.dtemissao,
            }),
            valor:
              parcela === cond_pagto?.nvezes
                ? valor_total - valor_lancado
                : valor,
            forma_pagto_id,
          });
          valor_lancado += valor;
        }
      }
      setValue(
        'pagamento',
        pagamento?.map((p, i) => ({ ...p, parcela: i + 1 }))
      );
    }
  };

  const onEditPagto = ({ values, index }) => {
    const pagamento = watch('pagamento');
    setValue(
      'pagamento',
      pagamento?.map((p, i) =>
        index === i
          ? { ...p, ...values, parcela: i + 1 }
          : { ...p, parcela: i + 1 }
      )
    );
    closeDialog();
  };

  const onDeletePagto = (index) => {
    setValue(
      'pagamento',
      watch('pagamento')?.filter((_, i) => i !== index)
    );
  };

  const onRecalc = (values) => {
    const valor_total = summarizer(values?.itens, 'subtotal');
    const parcelas = values?.pagamento?.length;
    if (Boolean(parcelas) && valor_total > 0) {
      let valor_lancado = 0;
      const pagamento = values?.pagamento.map((p, i) => {
        let valor =
          parcelas === i + 1
            ? valor_total - valor_lancado
            : parseFloat((valor_total / parcelas).toFixed(2));
        valor_lancado += valor;
        return { ...p, valor };
      });
      setValue('pagamento', pagamento);
    } else {
      setValue('pagamento', []);
    }
  };

  const onChangeEntidade = async (v) => {
    if (v) {
      const params = {
        entidade_id: v?.value,
        tipo: 'CLIENTE',
      };
      const { data } = await api.get(`/Cadastros/Entidade/Complemento`, {
        params,
      });
      if (data?.contribuinte_id) {
        setValue('contribuinte_id', data?.contribuinte_id);
      }
      if (data?.usofinal) {
        setValue('usofinal', data?.usofinal);
      }
      if (data?.vendedor_id) {
        setValue('vendedor_id', data?.vendedor_id);
      }
      if (data?.condicao_pagamento_id) {
        setValue('condicao_pagamento_id', data?.condicao_pagamento_id);
      }
      if (data?.forma_pagto_id) {
        setValue('forma_pagto_id', data?.forma_pagto_id);
      }
      if (data?.haver) {
        setValue('haver', data?.haver);
      }
    } else {
      setValue('contribuinte_id', 3);
      setValue('usofinal', 'NAO');
      setValue('haver', null);
      setValue('haver_uso', false);
    }
    setValue('entidade_endereco_id', null);
  };

  const onSubmit = (values) => {
    const data = {
      Documento: {
        especie_id: 8,
        natureza_operacao_id: values?.natureza_operacao_id,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.cadastro_id,
        dtemissao: values?.dtemissao,
        documento: values?.documento,
        observacao: values?.observacao,
        dadosadc: values?.dadosadc,
        contribuinte_id: values?.contribuinte_id,
        usofinal: values?.usofinal,
        indpres: values?.indpres,
      },
      Saida: {
        condicao_pagamento_id: values?.condicao_pagamento_id,
        vendedor_id: values?.vendedor_id,
        entidade_endereco_id: values?.entidade_endereco_id,
        dtsaida: moment().format('YYYY-MM-DD HH:mm:ss'),
        ped_cliente: values?.ped_cliente,
        forma_envio_id: values?.forma_envio_id,
        freteenv: values?.freteenv,
        fretecob: values?.fretecob,
        freterec: values?.freterec,
      },
      Moeda: {
        moeda_id: values?.moeda_id,
        cotacao: values?.cotacao,
      },
      Itens: values?.itens?.map((i, index) => ({
        ...i,
        sequencia: index + 1,
      })),
      Financeiros: values?.pagamento,
      Transportes: [
        {
          tipotransp: 'DESPACHO',
          entidade_id: values?.entidade_id,
          fretepc: values?.fretepc,
        },
      ],
      Totais: {
        frete: values?.fretevlr,
      },
    };
    postDocumento({ data, cb: () => navigate(-1) });
  };

  const options = [
    {
      name: 'Histórico de Vendas do Cliente',
      icon: 'work_history',
      action: () => {
        if (Boolean(watch('cadastro_id'))) {
          openDialog(
            <HistoricoVendaModal
              cadastro_id={watch('cadastro_id')}
              onSubmit={onAddItemDialog}
            />,
            'Histórico de Vendas do Cliente'
          );
        } else {
          toastWarning('Selecione um cliente');
        }
      },
    },
    {
      name: 'Histórico de Vendas do Produto',
      icon: 'work_history',
      action: () => {
        if (Boolean(watch('produto_id'))) {
          openDialog(
            <HistoricoVendaModal
              produto_id={watch('produto_id')}
              onSubmit={onAddItemDialog}
            />,
            'Histórico de Vendas do Produto'
          );
        } else {
          toastWarning('Selecione um Produto');
        }
      },
    },
    {
      name: 'Catálogo de Produtos',
      icon: 'list',
      action: () =>
        openDialog(
          <CatalogoModal
            onSubmit={onAddItemModal}
            vendedor_id={watch('vendedor_id')}
            cadastro_id={watch('cadastro_id')}
          />,
          'Catálogo de Produtos'
        ),
    },
  ];

  return (
    <Container>
      <Header titulo="Gerar Orçamento" />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="natureza_operacao_id"
              control={control}
              label="Natureza da Operação"
              options={drops?.NaturezaOperacao?.filter(
                (f) => f?.selecao === 'SIM'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="documento" control={control} label="Nº do Documento" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input
              name="ped_cliente"
              control={control}
              label="Nº do Pedido do Cliente"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data de Emissão"
              type="datetime"
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={10}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Entidade"
              options={drops?.Entidade}
              onValueChange={onChangeEntidade}
              refresh="Entidade"
            />
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <Visibility fontSize="small" />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Indicadores/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <QueryStats fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="contribuinte_id"
              control={control}
              label="Contribuinte"
              options={drops?.Contribuinte}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="usofinal"
              control={control}
              label="Uso Final"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="indpres"
              control={control}
              label="Indicativo Presencial"
              options={dropIndPres}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="vendedor_id"
              control={control}
              label="Vendedor"
              options={drops?.EntidadeTipo?.filter(
                (f) => f?.tipo === 'VENDEDOR' || f?.tipo === 'REPRESENTANTE'
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="entidade_endereco_id"
              control={control}
              label="Local de Fornecimento"
              options={drops?.EntidadeEndereco?.filter(
                (e) => e?.entidade_id === watch('cadastro_id')
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="moeda_id"
              control={control}
              label="Moeda para Conversão"
              options={drops?.Moeda}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="cotacao"
              control={control}
              label="Cotação da Moeda"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="dadosadc"
              control={control}
              label="Dados Adicionais"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined' }}
              options={options}
            />
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <VirtualDrop
                    name="produto_id"
                    control={control}
                    label="Produto"
                    options={drops?.Produto}
                    onValueChange={(v) =>
                      Boolean(v) && getAdditionals(v?.value)
                    }
                    refresh="Produto"
                    busca_refinada
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input name="descricao" control={control} label="Descrição" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="quantidade"
                    control={control}
                    label="Quantidade"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Dropdown
                    name="unidade_medida_id"
                    control={control}
                    label="Unidade de Medida"
                    options={drops?.UnidadeMedida}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Input
                    name="referencia"
                    control={control}
                    label="Referência"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="desconto1_pc"
                    control={control}
                    label="% Desconto"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="preco"
                    control={control}
                    label="Preço"
                    disabled={user?.permissoes?.block_altera_precos}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="przent"
                    control={control}
                    label="Prazo de Entrega"
                    type="date"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name="infadc"
                    control={control}
                    label="Informações Adicionais"
                  />
                </Grid>
                <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                  <Box
                    component="img"
                    sx={styles?.image}
                    src={additionals?.imagem || Blank}
                  />
                </Grid>
                {Boolean(loading) ? (
                  <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                    <Loader />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} {...styles?.textDetailsProps}>
                    <Typography variant="h6" color="primary">
                      {additionals?.descricao}
                    </Typography>
                    <Typography variant="body1">
                      Referência: {additionals?.referencia || '-'}
                    </Typography>
                    <Typography variant="body1">
                      Saldo: {additionals?.saldo || '0'}{' '}
                      {additionals?.UnidadeMedida?.descricao || ''}
                    </Typography>
                    <Typography variant="body1">
                      Localização: {additionals?.localizador || '-'}
                    </Typography>
                    <Typography variant="h6" align="right" color="primary">
                      {subtotal?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={handleSubmit(onAddItem)}
                  >
                    ADICIONAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Referência</TableCell>
                          <TableCell>Descrição</TableCell>
                          <TableCell>Quantidade</TableCell>
                          <TableCell>Desconto</TableCell>
                          <TableCell>Preço Unitário</TableCell>
                          <TableCell>Sub Total</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('itens')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.referencia}</TableCell>
                            <TableCell>{item?.descricao}</TableCell>
                            <TableCell>{item?.quantidade}</TableCell>
                            <TableCell>
                              {item?.desconto_vlr?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {item?.preco?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {item?.subtotal?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <ItemModal
                                      item={item}
                                      onSubmit={(values) =>
                                        onEditItem({ values, index })
                                      }
                                    />,
                                    'Editar Item'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeleteItem(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('itens'), 'subtotal')?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Pagamento" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="forma_pagto_id"
                    control={control}
                    label="Forma de Pagamento"
                    options={drops?.FormaDePagamento?.filter(
                      (f) => f?.modulo !== 'COMPRAS' && f?.modulo !== 'CHEQUES'
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="condicao_pagamento_id"
                    control={control}
                    label="Condição de Pagamento"
                    options={findOnArray(
                      watch('forma_pagto_id'),
                      drops?.FormaDePagamento,
                      'condicoes'
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox name="fixar" control={control} label="Fixar Dia" />
                </Grid>
                {Boolean(watch('haver')) && (
                  <Grid item xs={12}>
                    <Checkbox
                      name="haver_uso"
                      control={control}
                      label={`Utilizar Haver (${(
                        watch('haver') || 0
                      )?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })} disponível para uso)`}
                    />
                  </Grid>
                )}
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onChangePagto)}
                  >
                    CALCULAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Parcela</TableCell>
                          <TableCell>Data</TableCell>
                          <TableCell>Valor</TableCell>
                          <TableCell>Forma de Pagamento</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('pagamento')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.parcela}</TableCell>
                            <TableCell>
                              {Boolean(item?.dtvenc) &&
                                moment(item?.dtvenc).isValid() &&
                                moment(item?.dtvenc).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell>
                              {item?.valor?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {findOnArray(
                                item?.forma_pagto_id,
                                drops?.FormaDePagamento,
                                'label'
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <PagamentoModal
                                      item={item}
                                      onSubmit={(values) =>
                                        onEditPagto({ values, index })
                                      }
                                    />,
                                    'Editar Parcela'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeletePagto(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('pagamento'), 'valor')?.toLocaleString(
                'pt-br',
                { style: 'currency', currency: 'BRL' }
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Envio / Transporte" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="forma_envio_id"
              control={control}
              label="Forma de Envio"
              options={drops?.FormaDeEnvio}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="freteenv"
              control={control}
              label="Detalhes do Envio"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="fretecob"
              control={control}
              label="Frete Cobrado"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="freterec"
              control={control}
              label="Frete Recebido"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="fretevlr"
              control={control}
              label="Valor do Frete"
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="fretepc"
              control={control}
              label="Frete por Conta"
              options={dropFretePorConta}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="entidade_id"
              control={control}
              label="Transportador"
              options={drops?.EntidadeTipo?.filter(
                (t) => t?.tipo === 'TRANSPORTADOR'
              )}
            />
          </Grid>
        </Grid>
      </Card>
      <Box textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Box>
    </Container>
  );
};

export default Gerar;
