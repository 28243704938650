import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit, QueryStats, Visibility } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { dropFretePorConta, dropIndPres, dropBoolean } from 'utils/drops';
import {
  checkInfosProduto,
  findOnArray,
  summarizer,
  getDatePagto,
} from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import VirtualDrop from 'components/VirtualDrop';
import ButtonMenu from 'components/ButtonMenu';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import Blank from 'assets/blank.png';
import api from 'services/api';
import ItemModal from './Modals/ItemModal';
import ImportModal from './Modals/ImportModal';
import PagamentoModal from './Modals/PagamentoModal';
import styles from './styles';
import moment from 'moment';

const Gerar = () => {
  const { documento_id } = useParams();
  const { pathname } = useLocation();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postDocumentos, postLoading, getDocumento, getLoading, documento } =
    useContext(DocumentosContext);
  const defaultValues = {
    natureza_operacao_id: 6,
    documento: '',
    ped_cliente: '',
    dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
    filial_id: user?.filial_id,
    cadastro_id: null,
    contribuinte_id: null,
    usofinal: null,
    indpres: null,
    vendedor_id: null,
    celocal_id: null,
    entidade_endereco_id: null,
    observacao: '',
    dadosadc: '',
    //ITENS
    produto_id: null,
    descricao: '',
    quantidade: '',
    unidade_medida_id: null,
    referencia: '',
    desconto1_pc: '',
    desconto_vlr: '',
    preco: '',
    przent: null,
    planoconta_id: null,
    ccusto_id: null,
    aplicacao_id: null,
    frota_id: null,
    infadc: '',
    itens: [],
    //PAGAMENTO
    forma_pagto_id: null,
    condicao_pagamento_id: null,
    fixar: false,
    pagamento: [],
    //ENVIO/TRANSPORTE
    forma_envio_id: null,
    freteenv: '',
    fretecob: '',
    freterec: '',
    fretevlr: '',
    fretepc: null,
    entidade_id: null,
  };
  const navigate = useNavigate();
  const [additionals, setAdditionals] = useState(null);
  const [loading, setLoading] = useState(false);
  const { openDialog, closeDialog } = useDialog();
  const { control, handleSubmit, resetField, watch, setValue, getValues } =
    useForm({ defaultValues });
  const especie_id = pathname?.includes('/NF')
    ? 10
    : pathname?.includes('/Fatura')
    ? 25
    : pathname?.includes('/Cupom')
    ? 26
    : null;

  const renderValues = () => {
    const moved = documento?.DocumentoItems?.some((di) =>
      Boolean(di?.Ceexpedicaos?.length)
    );
    const transporte = documento?.DocumentoTransportes?.find(
      (f) => f?.tipotransp === 'DESPACHO'
    );
    const itens = documento?.DocumentoItems?.map((item) => {
      const Estruturas = item?.DocumentoItemEstruturas?.map((e) => {
        delete e?.Produto;
        delete e?.documento_item_id;
        delete e?.id;
        return e;
      });
      const Processos = item?.DocumentoItemProcessos?.map((p) => {
        delete p?.DocumentoItemProcessoAponts;
        delete p?.documento_item_id;
        delete p?.id;
        return p;
      });
      const Aprovacoes = item?.DocumentoItemAprovacaos?.map((a) => {
        delete a?.documento_item_id;
        delete a?.id;
        return a;
      });
      if (Boolean(Estruturas?.length)) item['Estruturas'] = Estruturas;
      if (Boolean(Processos?.length)) item['Processos'] = Processos;
      if (Boolean(Aprovacoes?.length)) item['Aprovacoes'] = Aprovacoes;
      const gerados = item?.Destinos?.filter(
        (d) => d?.especie_natureza === 'FATURA' && d?.especie_grupo === 'SAIDA'
      );
      const movimentados = Math.abs(
        summarizer(item?.Ceexpedicaos, 'quantidade') || 0
      );
      const qtpedido = item?.quantidade;
      let quantidade =
        (moved ? movimentados || 0 : item?.quantidade || 0) -
        summarizer(gerados, 'quantidade');
      let preco = item?.preco;
      const cotacao = documento?.DocumentoMoeda?.cotacao;
      const cotacao_atual = documento?.DocumentoMoeda?.cotacao_atual;
      if (Boolean(cotacao) && Boolean(cotacao_atual)) {
        preco = Number(
          (Number((preco / cotacao).toFixed(2)) * cotacao_atual).toFixed(2)
        );
      }
      item['quantidade_max'] = quantidade;
      item['preco_max'] = preco;
      if (especie_id === 26) {
        item['Vinculos'] = [{ documento_item_id_vinculo: item?.id }];
      }
      item['quantidade'] = quantidade;
      item['preco'] = preco;
      item['desconto_vlr'] = parseFloat(
        ((item?.desconto_vlr / qtpedido) * quantidade).toFixed(2)
      );
      item['despac'] = parseFloat(
        ((item?.despac / qtpedido) * quantidade).toFixed(2)
      );
      item['eventuais'] = parseFloat(
        ((item?.eventuais / qtpedido) * quantidade).toFixed(2)
      );
      item['frete'] = parseFloat(
        ((item?.frete / qtpedido) * quantidade).toFixed(2)
      );
      item['outros'] = parseFloat(
        ((item?.outros / qtpedido) * quantidade).toFixed(2)
      );
      item['seguro'] = parseFloat(
        ((item?.seguro / qtpedido) * quantidade).toFixed(2)
      );
      item['Origens'] = [{ documento_item_org_id: item?.id }];
      item['Margem'] = { ...item?.DocumentoItemMc };
      item['_doc'] = item?.documento_id;
      item['subtotal'] =
        (item?.quantidade || 0) * (item?.preco || 0) +
        (item?.desconto_vlr || 0);
      delete item?.Margem?.documento_item_id;
      delete item?.Cemovimentos;
      delete item?.Ceexpedicaos;
      delete item?.Cfo;
      delete item?.Destinos;
      delete item?.DocumentoItemAprovacaos;
      delete item?.DocumentoItemEstruturas;
      delete item?.DocumentoItemImpostos;
      delete item?.DocumentoItemMc;
      delete item?.DocumentoItemOrigems;
      delete item?.DocumentoItemProcessos;
      delete item?.DocumentoItemQualidades;
      delete item?.DocumentoItemVariacaos;
      delete item?.Kit;
      delete item?.Produto;
      delete item?.UnidadeMedida;
      delete item?.Vinculados;
      delete item?.Vinculos;
      delete item?.calcimp;
      delete item?.cfo_id;
      delete item?.documento_id;
      delete item?.id;
      return item;
    })?.filter((f) => Boolean(f?.quantidade));
    const forma_pagto_id = documento?.DocumentoFinanceiros?.filter(
      (x) => x?.FormaPagto?.modalidade !== '05-CRÉDITO LOJA'
    )?.find((f) => Boolean(f?.forma_pagto_id))?.forma_pagto_id;
    const pagamento = documento?.DocumentoFinanceiros?.map((fin) => ({
      parcela: fin?.parcela,
      dtvenc: fin?.dtvenc,
      valor: fin?.valor,
      forma_pagto_id: fin?.forma_pagto_id,
    }));
    if ([25, 26]?.includes(documento?.especie_id)) {
      setValue('natureza_operacao_id', null);
    } else {
      setValue('natureza_operacao_id', documento?.natureza_operacao_id || null);
    }
    setValue('ped_cliente', documento?.DocumentoSaida?.ped_cliente || '');
    setValue('filial_id', documento?.filial_id || null);
    setValue('cadastro_id', documento?.cadastro_id || null);
    setValue('contribuinte_id', documento?.contribuinte_id || null);
    setValue('usofinal', documento?.usofinal || null);
    setValue('indpres', documento?.indpres || null);
    setValue('vendedor_id', documento?.DocumentoSaida?.vendedor_id || null);
    setValue('celocal_id', documento?.celocal_id || null);
    setValue(
      'entidade_endereco_id',
      documento?.DocumentoSaida?.entidade_endereco_id || null
    );
    setValue('observacao', documento?.observacao || '');
    setValue('dadosadc', documento?.dadosadc || '');
    setValue('pagamento', pagamento);
    setValue('itens', itens);
    setValue('forma_pagto_id', forma_pagto_id);
    setValue(
      'condicao_pagamento_id',
      documento?.DocumentoSaida?.condicao_pagamento_id || null
    );
    setValue(
      'forma_envio_id',
      documento?.DocumentoSaida?.forma_envio_id || null
    );
    setValue('freteenv', documento?.DocumentoSaida?.freteenv || '');
    setValue('fretecob', documento?.DocumentoSaida?.fretecob || '');
    setValue('freterec', documento?.DocumentoSaida?.freterec || '');
    setValue('fretevlr', documento?.Totais?.frete || null);
    setValue('fretepc', transporte?.fretepc || null);
    setValue('entidade_id', transporte?.entidade_id || null);
  };

  useEffect(() => {
    if (Boolean(documento_id)) {
      getDocumento(documento_id);
    }
  }, []);

  useEffect(() => {
    if (Boolean(documento_id) && Boolean(documento)) {
      renderValues();
    }
  }, [documento]);

  useEffect(() => {
    const desconto_vlr =
      watch('desconto1_pc') * watch('preco') * watch('quantidade');
    setValue(
      'desconto_vlr',
      parseFloat(
        (desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr)?.toFixed(2)
      )
    );
  }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

  const subtotal =
    (watch('quantidade') || 0) * (watch('preco') || 0) +
    (watch('desconto_vlr') || 0);

  const getAdditionals = async (produto_id) => {
    setLoading(true);
    const produto = await checkInfosProduto({
      produto_id,
      disponivel: 'SIM',
      filial_id: watch('filial_id'),
      vendedor_id: watch('vendedor_id'),
      cadastro_id: watch('cadastro_id'),
      condicao_pagamento_id: watch('condicao_pagamento_id'),
    });
    setAdditionals(produto);
    setValue('descricao', produto?.descricao);
    setValue('desconto1_pc', produto?.desconto1_pc);
    setValue('quantidade', produto?.quantidade || 1);
    setValue('unidade_medida_id', produto?.unidade_medida_id);
    setValue('referencia', produto?.referencia);
    setValue('preco', produto?.preco);
    setLoading(false);
  };

  const onAddItem = (values) => {
    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue('itens', [
      {
        subtotal,
        produto_id: values?.produto_id,
        descricao: values?.descricao,
        quantidade: values?.quantidade,
        unidade_medida_id: values?.unidade_medida_id,
        referencia: values?.referencia,
        desconto1_pc: values?.desconto1_pc,
        desconto_vlr: values?.desconto_vlr,
        preco: values?.preco,
        przent: values?.przent,
        planoconta_id: values?.planoconta_id,
        ccusto_id: values?.ccusto_id,
        aplicacao_id: values?.aplicacao_id,
        frota_id: values?.frota_id,
        infadc: values?.infadc,
      },
      ...values?.itens,
    ]);
    setAdditionals(null);
    resetField('produto_id');
    resetField('descricao');
    resetField('quantidade');
    resetField('unidade_medida_id');
    resetField('referencia');
    resetField('desconto1_pc');
    resetField('desconto_vlr');
    resetField('preco');
    resetField('przent');
    resetField('planoconta_id');
    resetField('ccusto_id');
    resetField('aplicacao_id');
    resetField('frota_id');
    resetField('infadc');
    handleSubmit(onRecalc)();
  };

  const onImportItem = (values) => {
    setValue('itens', values);
    handleSubmit(onRecalc)();
    closeDialog();
  };

  const onEditItem = ({ values, index }) => {
    const itens = watch('itens');
    const item = itens[index];
    if (values?.quantidade > item?.quantidade_max) {
      return toastWarning(
        `Quantidade limite excedida. (Limite: ${item?.quantidade_max})`
      );
    }
    if (Boolean(item?.preco_max) && values?.preco > item?.preco_max) {
      return toastWarning(
        `Preço limite excedido. (Limite: ${item?.preco_max})`
      );
    }

    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue(
      'itens',
      itens?.map((itm, i) =>
        index === i ? { ...itm, ...values, subtotal } : itm
      )
    );
    handleSubmit(onRecalc)();
    closeDialog();
  };

  const onDeleteItem = (index) => {
    setValue(
      'itens',
      watch('itens')?.filter((_, i) => i !== index)
    );
    handleSubmit(onRecalc)();
  };

  const onChangePagto = (values) => {
    const forma_pagto_id = values?.forma_pagto_id;
    const condicao_pagamento_id = values?.condicao_pagamento_id;
    const fixar = values?.fixar;
    if (Boolean(forma_pagto_id) && Boolean(condicao_pagamento_id)) {
      const condicoes =
        findOnArray(forma_pagto_id, drops?.FormaDePagamento, 'condicoes') || [];
      const cond_pagto = findOnArray(condicao_pagamento_id, condicoes);
      const valor_total = summarizer(values?.itens, 'subtotal');

      if (cond_pagto && valor_total > 0) {
        const pagamento = [];
        let valor_lancado = 0;
        for (let parcela = 1; parcela <= cond_pagto?.nvezes; parcela++) {
          const valor = parseFloat(
            (valor_total / cond_pagto?.nvezes).toFixed(2)
          );
          pagamento.push({
            parcela,
            dtvenc: getDatePagto({
              ...cond_pagto,
              parcela,
              fixar,
              start_date: values?.dtemissao,
            }),
            valor:
              parcela === cond_pagto?.nvezes
                ? valor_total - valor_lancado
                : valor,
            forma_pagto_id,
          });
          valor_lancado += valor;
        }
        setValue('pagamento', pagamento);
      } else {
        setValue('pagamento', []);
      }
    }
  };

  const onEditPagto = ({ values, index }) => {
    const pagamento = watch('pagamento');
    setValue(
      'pagamento',
      pagamento?.map((p, i) =>
        index === i
          ? { ...p, ...values, parcela: i + 1 }
          : { ...p, parcela: i + 1 }
      )
    );
    closeDialog();
  };

  const onDeletePagto = (index) => {
    setValue(
      'pagamento',
      watch('pagamento')?.filter((_, i) => i !== index)
    );
  };

  const onRecalc = (values) => {
    const valor_total = summarizer(values?.itens, 'subtotal');
    const parcelas = values?.pagamento?.length;
    if (Boolean(parcelas) && valor_total > 0) {
      let valor_lancado = 0;
      const pagamento = values?.pagamento.map((p, i) => {
        let valor =
          parcelas === i + 1
            ? valor_total - valor_lancado
            : parseFloat((valor_total / parcelas).toFixed(2));
        valor_lancado += valor;
        return { ...p, valor };
      });
      setValue('pagamento', pagamento);
    } else {
      setValue('pagamento', []);
    }
  };

  const onChangeEntidade = async (v) => {
    if (v) {
      const params = {
        entidade_id: v?.value,
        tipo: 'CLIENTE',
      };
      const { data } = await api.get(`/Cadastros/Entidade/Complemento`, {
        params,
      });
      if (data?.contribuinte_id) {
        setValue('contribuinte_id', data?.contribuinte_id);
      }
      if (data?.usofinal) {
        setValue('usofinal', data?.usofinal);
      }
      if (data?.vendedor_id) {
        setValue('vendedor_id', data?.vendedor_id);
      }
      if (data?.condicao_pagamento_id) {
        setValue('condicao_pagamento_id', data?.condicao_pagamento_id);
      }
      if (data?.forma_pagto_id) {
        setValue('forma_pagto_id', data?.forma_pagto_id);
      }
    } else {
      setValue('contribuinte_id', 3);
      setValue('usofinal', 'NAO');
    }
    setValue('entidade_endereco_id', null);
  };

  const onSubmit = (values) => {
    const tipo = documento?.DocumentoSaida?.divisaotp;
    const percentual = documento?.DocumentoSaida?.divisaopc / 100;
    const split = Boolean(documento_id) && Boolean(tipo) && Boolean(percentual);
    let itens_1 = [];
    let itens_2 = [];
    if (split && tipo === 'VALOR') {
      itens_1 = values?.itens?.map((it) => {
        const item = { ...it };
        item['preco'] = parseFloat((item?.preco * percentual)?.toFixed(2));
        item['desconto_vlr'] = parseFloat(
          (item?.desconto_vlr * percentual)?.toFixed(2)
        );
        item['eventuais'] = parseFloat(
          (
            (item?.eventuais / item?.quantidade_max) *
            item?.quantidade *
            percentual
          )?.toFixed(2)
        );
        item['frete'] = parseFloat(
          (
            (item?.frete / item?.quantidade_max) *
            item?.quantidade *
            percentual
          )?.toFixed(2)
        );
        item['outros'] = parseFloat(
          (
            (item?.outros / item?.quantidade_max) *
            item?.quantidade *
            percentual
          )?.toFixed(2)
        );
        item['despac'] = parseFloat(
          (
            (item?.despac / item?.quantidade_max) *
            item?.quantidade *
            percentual
          )?.toFixed(2)
        );
        item['seguro'] = parseFloat(
          (
            (item?.seguro / item?.quantidade_max) *
            item?.quantidade *
            percentual
          )?.toFixed(2)
        );
        return item;
      });
      itens_2 = values?.itens?.map((it) => {
        const item = { ...it };
        item['preco'] = parseFloat(
          (
            item?.preco * item?.quantidade -
            item?.quantidade *
              parseFloat((item?.preco * percentual)?.toFixed(2))
          ).toFixed(2)
        );
        item['desconto_vlr'] = parseFloat(
          (
            item.desconto_vlr -
            parseFloat((item?.desconto_vlr * percentual)?.toFixed(2))
          )?.toFixed(2)
        );
        item['eventuais'] = parseFloat(
          (
            item.eventuais -
            parseFloat((item?.eventuais * percentual)?.toFixed(2))
          )?.toFixed(2)
        );
        item['frete'] = parseFloat(
          (
            item.frete - parseFloat((item?.frete * percentual)?.toFixed(2))
          )?.toFixed(2)
        );
        item['outros'] = parseFloat(
          (
            item.outros - parseFloat((item?.outros * percentual)?.toFixed(2))
          )?.toFixed(2)
        );
        item['despac'] = parseFloat(
          (
            item.despac - parseFloat((item?.despac * percentual)?.toFixed(2))
          )?.toFixed(2)
        );
        item['seguro'] = parseFloat(
          (
            item.seguro - parseFloat((item?.seguro * percentual)?.toFixed(2))
          )?.toFixed(2)
        );
        item['quantidade'] = 0;
        return item;
      });
    } else if (split && tipo === 'QUANTIDADE') {
      itens_1 = values?.itens
        ?.map((it, i) => {
          const item = { ...it };
          let sqte = item?.quantidade_max;
          item['quantidade'] = Math.ceil(item?.quantidade * percentual);
          item['desconto_vlr'] = parseFloat(
            ((item?.desconto_vlr / sqte) * item?.quantidade)?.toFixed(2)
          );
          item['eventuais'] = parseFloat(
            ((item?.eventuais / sqte) * item?.quantidade)?.toFixed(2)
          );
          item['frete'] = parseFloat(
            ((item?.frete / sqte) * item?.quantidade)?.toFixed(2)
          );
          item['outros'] = parseFloat(
            ((item?.outros / sqte) * item?.quantidade)?.toFixed(2)
          );
          item['despac'] = parseFloat(
            ((item?.despac / sqte) * item?.quantidade)?.toFixed(2)
          );
          item['seguro'] = parseFloat(
            ((item?.seguro / sqte) * item?.quantidade)?.toFixed(2)
          );
          return item;
        })
        ?.filter((f) => Boolean(f?.quantidade));
      itens_2 = values?.itens
        ?.map((it, i) => {
          const item = { ...it };
          let sqte = item?.quantidade_max;
          item['quantidade'] =
            item?.quantidade - Math.ceil(item?.quantidade * percentual);
          item['desconto_vlr'] = parseFloat(
            (
              item.desconto_vlr -
              parseFloat(
                (
                  (item?.desconto_vlr / sqte) *
                  Math.ceil(item?.quantidade * percentual)
                )?.toFixed(2)
              )
            )?.toFixed(2)
          );
          item['eventuais'] = parseFloat(
            (
              item.eventuais -
              parseFloat(
                (
                  (item?.eventuais / sqte) *
                  Math.ceil(item?.quantidade * percentual)
                )?.toFixed(2)
              )
            )?.toFixed(2)
          );
          item['frete'] = parseFloat(
            (
              item.frete -
              parseFloat(
                (
                  (item?.frete / sqte) *
                  Math.ceil(item?.quantidade * percentual)
                )?.toFixed(2)
              )
            )?.toFixed(2)
          );
          item['outros'] = parseFloat(
            (
              item.outros -
              parseFloat(
                (
                  (item?.outros / sqte) *
                  Math.ceil(item?.quantidade * percentual)
                )?.toFixed(2)
              )
            )?.toFixed(2)
          );
          item['despac'] = parseFloat(
            (
              item.despac -
              parseFloat(
                (
                  (item?.despac / sqte) *
                  Math.ceil(item?.quantidade * percentual)
                )?.toFixed(2)
              )
            )?.toFixed(2)
          );
          item['seguro'] = parseFloat(
            (
              item.seguro -
              parseFloat(
                (
                  (item?.seguro / sqte) *
                  Math.ceil(item?.quantidade * percentual)
                )?.toFixed(2)
              )
            )?.toFixed(2)
          );
          return item;
        })
        ?.filter((f) => Boolean(f?.quantidade));
    } else {
      itens_1 = values?.itens?.map((it) => {
        const item = { ...it };
        let sqte = item?.quantidade_max;
        item['eventuais'] = parseFloat(
          ((item?.eventuais / sqte) * item?.quantidade)?.toFixed(2)
        );
        item['frete'] = parseFloat(
          ((item?.frete / sqte) * item?.quantidade)?.toFixed(2)
        );
        item['outros'] = parseFloat(
          ((item?.outros / sqte) * item?.quantidade)?.toFixed(2)
        );
        item['despac'] = parseFloat(
          ((item?.despac / sqte) * item?.quantidade)?.toFixed(2)
        );
        item['seguro'] = parseFloat(
          ((item?.seguro / sqte) * item?.quantidade)?.toFixed(2)
        );
        return item;
      });
    }
    const data = [];
    if (split) {
      const nota = JSONBuilder({
        values,
        especie_id: 10,
        Itens: itens_1,
      });
      const fatura = JSONBuilder({
        values,
        especie_id: 25,
        Itens: itens_2,
      });
      data?.push(nota);
      data?.push(fatura);
    } else {
      const nota = JSONBuilder({
        values,
        especie_id,
        Itens: itens_1,
      });
      data?.push(nota);
    }
    postDocumentos({ data, cb: () => navigate(-1) });
  };

  const JSONBuilder = ({ values, especie_id, Itens = [] }) => {
    const Transportes = [];
    const hasTransp = documento?.DocumentoTransportes?.some(
      (s) => s?.tipotransp === 'DESPACHO'
    );
    if (Boolean(documento_id) && hasTransp) {
      documento?.DocumentoTransportes?.map((t) => {
        delete t?.id;
        delete t?.documento_id;
        if (t?.tipotransp === 'DESPACHO') {
          Transportes?.push({
            ...t,
            entidade_id: values?.entidade_id,
            fretepc: values?.fretepc,
          });
        } else {
          Transportes?.push(t);
        }
      });
    } else {
      Transportes?.push({
        tipotransp: 'DESPACHO',
        entidade_id: values?.entidade_id,
        fretepc: values?.fretepc,
      });
    }
    let data = {
      Documento: {
        especie_id,
        natureza_operacao_id: values?.natureza_operacao_id,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.cadastro_id,
        celocal_id: values?.celocal_id,
        dtemissao: values?.dtemissao,
        documento: values?.documento,
        observacao: values?.observacao,
        dadosadc: values?.dadosadc,
        contribuinte_id: values?.contribuinte_id,
        usofinal: values?.usofinal,
        indpres: values?.indpres,
      },
      Saida: {
        condicao_pagamento_id: values?.condicao_pagamento_id,
        vendedor_id: values?.vendedor_id,
        entidade_endereco_id: values?.entidade_endereco_id,
        ped_cliente: values?.ped_cliente,
        forma_envio_id: values?.forma_envio_id,
        freteenv: values?.freteenv,
        fretecob: values?.fretecob,
        freterec: values?.freterec,
      },
      Itens: Itens?.map((item, s) => ({
        ...item,
        sequencia: s + 1,
      })),
      Financeiros: values?.pagamento,
      Transportes,
    };
    if (Boolean(values?.fretevlr)) {
      data = { ...data, Totais: { frete: values?.fretevlr } };
    }
    if (Boolean(documento_id)) {
      data = {
        ...data,
        Origens: [...new Set(Itens?.map((i) => i?._doc))]?.map(
          (documento_id) => ({ documento_id })
        ),
        Saida: {
          ...data?.Saida,
          marketplace_order_id: documento?.DocumentoSaida?.marketplace_order_id,
          documento: documento?.DocumentoSaida?.documento,
          validade: documento?.DocumentoSaida?.validade,
          dtsaida: documento?.DocumentoSaida?.dtsaida,
          dtatend: documento?.DocumentoSaida?.dtatend,
          desc1: documento?.DocumentoSaida?.desc1,
          desc2: documento?.DocumentoSaida?.desc2,
          desc3: documento?.DocumentoSaida?.desc3,
          desc4: documento?.DocumentoSaida?.desc4,
          desc5: documento?.DocumentoSaida?.desc5,
        },
      };
    }
    if (Boolean(documento_id) && Boolean(documento?.DocumentoMoeda)) {
      data = {
        ...data,
        Moeda: {
          moeda_id: documento?.DocumentoMoeda?.moeda_id,
          cotacao: documento?.DocumentoMoeda?.cotacao,
        },
      };
    }
    if (
      Boolean(documento_id) &&
      Boolean(documento?.DocumentoServico) &&
      documento?.NaturezaOperacao?.natureza === 'SERVICOS'
    ) {
      data = {
        ...data,
        Servico: {
          garantia: documento?.DocumentoServico?.garantia,
          assunto: documento?.DocumentoServico?.assunto,
          defeito: documento?.DocumentoServico?.defeito,
          solicitacao: documento?.DocumentoServico?.solicitacao,
          mail_contato: documento?.DocumentoServico?.mail_contato,
        },
      };
    }
    return data;
  };

  const dropNatureza = () => {
    return (
      drops?.NaturezaOperacao?.filter((f) => {
        if (
          [25, 26]?.includes(documento?.especie_id) &&
          Boolean(documento_id)
        ) {
          return (
            f?.Operação === 'SAIDA' &&
            f?.selecao === 'SIM' &&
            f?.estoque === 'NAO' &&
            f?.financeiro === 'NAO'
          );
        }
        return f?.selecao === 'SIM';
      }) || []
    );
  };

  const options = [
    {
      name: 'Importar Itens',
      icon: 'upload',
      action: () => {
        const { cadastro_id, itens } = getValues();
        if (Boolean(cadastro_id)) {
          openDialog(
            <ImportModal
              especie_id={9}
              cadastro_id={cadastro_id}
              itens={itens}
              onSubmit={onImportItem}
            />,
            'Importar Itens'
          );
        } else {
          toastWarning('Entidade não informada');
        }
      },
    },
  ];

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header
        titulo={
          'Gerar ' +
          (especie_id === 10
            ? 'Nota'
            : especie_id === 25
            ? 'Fatura'
            : especie_id === 26
            ? 'Cupom'
            : '')
        }
      />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="natureza_operacao_id"
              control={control}
              label="Natureza da Operação"
              options={dropNatureza()}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input name="documento" control={control} label="Nº do Documento" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input
              name="ped_cliente"
              control={control}
              label="Nº do Pedido do Cliente"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data de Emissão"
              type="datetime"
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={10}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Entidade"
              options={drops?.Entidade}
              onValueChange={onChangeEntidade}
              refresh="Entidade"
            />
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <Visibility fontSize="small" />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Indicadores/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <QueryStats fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="contribuinte_id"
              control={control}
              label="Contribuinte"
              options={drops?.Contribuinte}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="usofinal"
              control={control}
              label="Uso Final"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="indpres"
              control={control}
              label="Indicativo Presencial"
              options={dropIndPres}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="vendedor_id"
              control={control}
              label="Vendedor"
              options={drops?.EntidadeTipo?.filter(
                (f) => f?.tipo === 'VENDEDOR' || f?.tipo === 'REPRESENTANTE'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="celocal_id"
              control={control}
              label="Local de Estoque"
              options={drops?.LocalEstoque?.filter(
                (f) =>
                  !Boolean(f?.filial_id) || f?.filial_id === watch('filial_id')
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="entidade_endereco_id"
              control={control}
              label="Local de Fornecimento"
              options={drops?.EntidadeEndereco?.filter(
                (e) => e?.entidade_id === watch('cadastro_id')
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="dadosadc"
              control={control}
              label="Dados Adicionais"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined' }}
              options={options}
            />
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <VirtualDrop
                    name="produto_id"
                    control={control}
                    label="Produto"
                    options={drops?.Produto}
                    onValueChange={(v) =>
                      Boolean(v) && getAdditionals(v?.value)
                    }
                    refresh="Produto"
                    busca_refinada
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input name="descricao" control={control} label="Descrição" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="quantidade"
                    control={control}
                    label="Quantidade"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Dropdown
                    name="unidade_medida_id"
                    control={control}
                    label="Unidade de Medida"
                    options={drops?.UnidadeMedida}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Input
                    name="referencia"
                    control={control}
                    label="Referência"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="desconto1_pc"
                    control={control}
                    label="% Desconto"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="preco"
                    control={control}
                    label="Preço"
                    disabled={user?.permissoes?.block_altera_precos}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="przent"
                    control={control}
                    label="Prazo de Entrega"
                    type="date"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="planoconta_id"
                    control={control}
                    label="Plano de Conta"
                    options={drops?.Planoconta?.filter(
                      (p) => p?.operacional === 'SIM'
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="ccusto_id"
                    control={control}
                    label="Centro de Custo"
                    options={drops?.Ccusto}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="aplicacao_id"
                    control={control}
                    label="Aplicação"
                    options={drops?.Aplicacao}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="frota_id"
                    control={control}
                    label="Frota"
                    options={drops?.Frota}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name="infadc"
                    control={control}
                    label="Informações Adicionais"
                  />
                </Grid>
                <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                  <Box
                    component="img"
                    sx={styles?.image}
                    src={additionals?.imagem || Blank}
                  />
                </Grid>
                {Boolean(loading) ? (
                  <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                    <Loader />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} {...styles?.textDetailsProps}>
                    <Typography variant="h6" color="primary">
                      {additionals?.descricao}
                    </Typography>
                    <Typography variant="body1">
                      Referência: {additionals?.referencia || '-'}
                    </Typography>
                    <Typography variant="body1">
                      Saldo: {additionals?.saldo || '0'}{' '}
                      {additionals?.UnidadeMedida?.descricao || ''}
                    </Typography>
                    <Typography variant="body1">
                      Localização: {additionals?.localizador || '-'}
                    </Typography>
                    <Typography variant="h6" align="right" color="primary">
                      {subtotal?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={handleSubmit(onAddItem)}
                  >
                    ADICIONAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Referência</TableCell>
                          <TableCell>Descrição</TableCell>
                          <TableCell>Quantidade</TableCell>
                          <TableCell>Desconto</TableCell>
                          <TableCell>Preço Unitário</TableCell>
                          <TableCell>Sub Total</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('itens')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.referencia}</TableCell>
                            <TableCell>{item?.descricao}</TableCell>
                            <TableCell>{item?.quantidade}</TableCell>
                            <TableCell>
                              {item?.desconto_vlr?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {item?.preco?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {item?.subtotal?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <ItemModal
                                      item={item}
                                      onSubmit={(values) =>
                                        onEditItem({ values, index })
                                      }
                                    />,
                                    'Editar Item'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeleteItem(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('itens'), 'subtotal')?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Pagamento" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="forma_pagto_id"
                    control={control}
                    label="Forma de Pagamento"
                    options={drops?.FormaDePagamento?.filter(
                      (f) => f?.modulo !== 'COMPRAS' && f?.modulo !== 'CHEQUES'
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="condicao_pagamento_id"
                    control={control}
                    label="Condição de Pagamento"
                    options={findOnArray(
                      watch('forma_pagto_id'),
                      drops?.FormaDePagamento,
                      'condicoes'
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox name="fixar" control={control} label="Fixar Dia" />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onChangePagto)}
                  >
                    CALCULAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Parcela</TableCell>
                          <TableCell>Data</TableCell>
                          <TableCell>Valor</TableCell>
                          <TableCell>Forma de Pagamento</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('pagamento')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.parcela}</TableCell>
                            <TableCell>
                              {Boolean(item?.dtvenc) &&
                                moment(item?.dtvenc).isValid() &&
                                moment(item?.dtvenc).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell>
                              {item?.valor?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {findOnArray(
                                item?.forma_pagto_id,
                                drops?.FormaDePagamento,
                                'label'
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <PagamentoModal
                                      item={item}
                                      onSubmit={(values) =>
                                        onEditPagto({ values, index })
                                      }
                                    />,
                                    'Editar Parcela'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeletePagto(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('pagamento'), 'valor')?.toLocaleString(
                'pt-br',
                { style: 'currency', currency: 'BRL' }
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Envio / Transporte" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="forma_envio_id"
              control={control}
              label="Forma de Envio"
              options={drops?.FormaDeEnvio}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="freteenv"
              control={control}
              label="Detalhes do Envio"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="fretecob"
              control={control}
              label="Frete Cobrado"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="freterec"
              control={control}
              label="Frete Recebido"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="fretevlr"
              control={control}
              label="Valor do Frete"
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="fretepc"
              control={control}
              label="Frete por Conta"
              options={dropFretePorConta}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="entidade_id"
              control={control}
              label="Transportador"
              options={drops?.EntidadeTipo?.filter(
                (t) => t?.tipo === 'TRANSPORTADOR'
              )}
            />
          </Grid>
        </Grid>
      </Card>
      <Box textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Box>
    </Container>
  );
};

export default Gerar;
